import PlayerList from "./PlayerList";
import TeamList from "./TeamList";

function GameDetails({game}) {
  return (
    <div className={`dcol fullW ${game}`}>
      <span className="coming-soon">Coming soon</span>
      {/* <div className="drow fullW gap3">
        <TeamList className="flex2" />
        <PlayerList className="flex2" />
        <div className="dcol flex1">
          <PlayerList className="flex1" />
          <PlayerList className="flex1" />
        </div>
      </div> */}
    </div>
  );
}

export default GameDetails;
