import { Breadcrumb, Image, Tabs } from "antd";

import gameList, { getGameLogo, getItemObj } from "../../helper/Games.helper";
import GameDetails from "./details/Details";
import { HomeOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedMenu } from "../../store/components/Main.slice";
import { useMemo } from "react";
import UpperContainer from "../general/UpperContainer";

export const GameItem = ({ data: { name, label, icon, key } }) => {
  return (
    <div className={`drow gap2 align-items-center gameitem ${key}`}>
      {icon && (
        <div className="gameitem-logo">
          <Image preview={false} src={icon} />
        </div>
      )}
      {name ||
        (label && (
          <div className="gameitem-name-bg">
            <span className="gameitem-name">{name || label}</span>
          </div>
        ))}
    </div>
  );
};

function Games() {
  const dispatch = useDispatch();
  const { selectedMenu } = useSelector((state) => state.main);

  const seledGameObj = useMemo(() => getItemObj(selectedMenu), [selectedMenu]);

  return (
    <div className="esportslookout-games">
      <UpperContainer>
        <Breadcrumb
          className="drow alig-items-center"
          separator="›"
          items={[
            {
              onClick: () => {
                dispatch(setSelectedMenu("home"));
              },
              href: "",
              title: <HomeOutlined title="Home" />,
            },
            {
              title: "Games",
            },
            {
              title: <GameItem data={seledGameObj} />,
            },
          ]}
        />
      </UpperContainer>
      {/* <Tabs
        defaultActiveKey="1"
        centered
        items={gameList.map((game) => {
          const { name, key } = game;
          return {
            icon: <Image src={getGameLogo(key)} preview={false} />,
            label: name,
            key,
            children: <GameDetails game={key} />,
          };
        })}
      /> */}
    </div>
  );
}

export default Games;
