export const color = {
  darkBackground: "#020913",
  darkBackground2: "#030a15",
  fontColor: "#87abab",
  fontColorActive: "#26e3e3",
  darkBackgroundGradient_1:
    "linear-gradient(0deg, rgba(2,9,19,1) 0%, rgba(9,20,40,1) 50%, rgba(2,9,19,1) 100%)",
  darkBackgroundGradient_2:
    "linear-gradient(0deg, rgba(9,20,40,1) 0%, rgba(2,9,19,1) 100%)",
};
const Theme = () => {
  return {
    dark: {
      components: {
        Layout: {
          bodyBg: color.darkBackground2,
          headerBg: color.darkBackground,
          footerBg: color.darkBackground,
        },
        Menu: {
          darkSubMenuItemBg: color.darkBackground,
          darkPopupBg: color.darkBackground,
          darkItemBg: color.darkBackground,
          darkItemSelectedBg: color.darkBackground,
          darkItemColor: color.fontColor,
          darkItemHoverColor: color.fontColorActive,
          darkItemSelectedColor: color.fontColorActive,
        },
        Checkbox: {
          // colorBorder: color.darkBorder,
        },
      },
      token: {
        // colorBorder: color.darkBorder,
        // colorBgContainer: color.darkContent,
        // colorBgElevated: color.darkBody,
        // colorBgHeader: color.darkHeader,
        // colorIcon: color.darkText,
        // colorText: color.darkText,
        // colorBgMask: color.maskColor,
        // colorLink: color.darkTextLink,
        // colorLinkHover: color.darkTextLinkHover,
        // colorError: color.darkError,
        // boxShadow: color.darkShadow,
        // controlItemBgActive: color.darkItemActive,
        // controlItemBgHover: color.darkItemHover,
        // controlItemBgActiveHover: color.darkItemHover,
        // controlOutlineWidth: 0,
        // linkHoverDecoration: "underline",
      },
    },
  };
};

export default Theme;
