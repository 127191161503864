import { configureStore } from "@reduxjs/toolkit";

import mainReducer from "./components/Main.slice";

const rootReducer = {
  main: mainReducer,
};

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
});

export default store;
