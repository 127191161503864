import React from "react";
import Body from "../components/body/Body";
import Home from "../components/home/Home";
import Games from "../components/games/Games";

const Routes = () => {
  return [
    {
      path: "/",
      element: <Body />,
      children: [
        {
          path: "/home",
          element: <Home />,
        },
        {
          path: "/games/:gamename",
          element: <Games />,
        },
      ],
    },
  ];
};

export default Routes;
