import { createHashRouter, RouterProvider } from "react-router-dom";
import Routes from "../../routes/Routes";
import { ConfigProvider, theme } from "antd";
import Theme from "../theme/Theme";

const currentTheme = "dark";
function App() {
  const themeParams = {
    algorithm:
      currentTheme === "dark"
        ? theme.darkAlgorithm
        : theme.defaultAlgorithm,
    ...Theme()[currentTheme],
  }
  return (
    <ConfigProvider
      theme={themeParams}
    >
      <RouterProvider router={createHashRouter(Routes())} />
    </ConfigProvider>
  );
}

export default App;
