import { Image, Menu } from "antd";

import gameList, { getGameLogo } from "../../helper/Games.helper";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedMenu } from "../../store/components/Main.slice";
import { DownOutlined, HomeOutlined } from "@ant-design/icons";

function HeaderMenu() {
  const dispatch = useDispatch();
  const { selectedMenu } = useSelector((state) => state.main);
  return (
    <div className="esportslookout-header-menu flex1">
      <Menu
        onClick={(e) => {
          dispatch(setSelectedMenu(e.key));
        }}
        selectedKeys={[selectedMenu]}
        theme="dark"
        mode="horizontal"
        expandIcon={<DownOutlined />}
        items={[
          {
            label: "Home",
            key: "home",
            icon: <HomeOutlined />,
          },
          {
            label: "Games",
            key: "games",
            children: gameList.map((game) => {
              const { name, key } = game;
              return {
                icon: (
                  <Image
                    src={getGameLogo(key)}
                    preview={false}
                    style={{
                      width: "3.5mm",
                      marginRight: "2mm",
                    }}
                  />
                ),
                label: name,
                key,
              };
            }),
          },
        ]}
      />
    </div>
  );
}

export default HeaderMenu;
