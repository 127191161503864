/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedMenu: "home",
};
const main = createSlice({
  name: "main",
  initialState: { ...initialState },
  reducers: {
    setSelectedMenu: (state, action) => {
      state.selectedMenu = action.payload;
    },
  },
});

const { reducer, actions } = main;
export const { setSelectedMenu } = actions;
export default reducer;
