import { Image, Layout } from "antd";

import HeaderMenu from "./HeaderMenu";
import logo from "../../images/logo/esportslookout.jpg";

function Header() {
  return (
    <Layout.Header className="esportslookout-header drow align-items-center gap2">
      <div className="esportslookout-header-logo drow align-items-center">
        <div className="esportslookout-header-logo-text drow align-items-center gap2">
          <Image className="esportslookout-header-logo-img" src={logo} preview={false} />
          <div className="header-appname">ESPORTS</div>
          <div className="header-subappname">Lookout</div>
        </div>
      </div>
      <HeaderMenu />
    </Layout.Header>
  );
}

export default Header;
