import gameList from "../config/games.json";

// Logos
import lol from "../images/logo/lol.png";
import dota2 from "../images/logo/dota2.png";
import valo from "../images/logo/valo.png";

const gameLogos = {
  lol,
  dota2,
  valo,
};

export const getGameLogo = (key) => gameLogos[key];

export const isGame = (key) => !!getGameLogo(key);

export const getItemObj = (key) => {
  const foundGame = gameList.find((g) => g.key === key);
  if (!foundGame) return {};

  return {
    icon: getGameLogo(key),
    label: foundGame.name,
    key: foundGame.key,
  };
};
export default gameList;
