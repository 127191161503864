import { Outlet, useNavigate } from "react-router-dom";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { Layout } from "antd";
import { isGame } from "../../helper/Games.helper";

function Body() {
  const navigate = useNavigate();

  const { selectedMenu } = useSelector((state) => state.main);

  useEffect(() => {
    if (selectedMenu) {
      if (isGame(selectedMenu)) {
        navigate(`/games/${selectedMenu}`);
      } else {
        navigate(`/${selectedMenu}`);
      }
    }
  }, [selectedMenu]);

  return (
    <Layout className="esportslookout">
      <Header />
      <Layout.Content>
        <div className="esportslookout-body">
          <Outlet />
        </div>
        <Footer />
      </Layout.Content>
    </Layout>
  );
}

export default Body;
