import { color } from "../theme/Theme";

function UpperContainer({ children }) {
  return (
    <div
      className="fullW"
      style={{
        height: "70vh",
        background: color.darkBackgroundGradient_2,
        padding: "4mm",
      }}
    >
      {children}
    </div>
  );
}

export default UpperContainer;
