import UpperContainer from "../general/UpperContainer";

function Home() {
  return (
    <div
      className="esportslookout-home fullW"
      style={{
        height: "100%",
      }}
    >
      <UpperContainer>Home</UpperContainer>
    </div>
  );
}

export default Home;
